<template>
	<div class="box box_mm">
		<div class="box_main">
			<div class="search_box">
				<div class="search_item">
					<span style="width: 0.6rem;">标题：</span>
					<el-input v-model="searchinput" placeholder="请输入标题" style="width: 2rem;"></el-input>
				</div>
				<div class="search_item">
					<el-button type="primary" @click="search">搜索</el-button>
					<el-button type="info" @click="reset">重置</el-button>					
					<el-button type="success" @click="addnew">新增</el-button>
				</div>
			</div>
			<div class="box_cont">
				<div class="btn_list">
				</div>
				<div style="height: 76vh;box-sizing: border-box;">
					<MyTable ref="zjlist" :tableData="tableData" :tableHeader="tableHeader" :showbm="true"
						:czwidth="180" @getdata="gettable">
						<template slot="btns" slot-scope="{ scope }">
							<el-button @click="showedit(scope.row)" type="warning" size="mini">编辑</el-button>
							<el-button @click="deleterow(scope.row)" type="danger" size="mini">删除</el-button>
						</template>
					</MyTable>
				</div>
			</div>
		</div>
		
		<!--新增、编辑-->
		<el-dialog :title="isadd?'新增':'编辑'" :visible.sync="showdetail" :before-close="closedb" width="40%">
			<div style="width: 100%;">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
					<el-form-item label="标题" prop="title">
						<el-input v-model="ruleForm.title"></el-input>
					</el-form-item>
					<el-form-item label="所属书籍" prop="type">
					  <el-select v-model="ruleForm.type" filterable placeholder="请选择">
					      <el-option
					        v-for="item in booklist"
					        :key="item.id"
					        :label="item.title"
					        :value="item.id">
					      </el-option>
					    </el-select>
					</el-form-item>
					<el-form-item label="缩略图" prop="slt_url">
						<el-upload class="avatar-uploader" :action="baseurl + '/api/login/upload'"
							:show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
							<img v-if="ruleForm.slt_url" :src="ruleForm.slt_url" class="avatar">
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
					</el-form-item>
					<el-form-item label="附件" prop="fj_url">
						<el-upload
						  class="upload-demo"
						  :action="baseurl+'/api/login/upload'" :headers="{
						  		token:$store.state.userinfo.token
						  	}"
						  :limit="1"
						  :on-success="handleAvatarSuccessfile"
						  :on-preview="showpreview"
						  :on-remove="handleChange"
						  :file-list="fileList">
						  <el-button size="small" type="primary">点击上传</el-button>
						</el-upload>
					</el-form-item>
					<el-form-item label="排序">
						<el-input v-model="ruleForm.sort" type="number"></el-input>
					</el-form-item>
				</el-form>
			</div>
		
			<span style="width: 100%;display: flex;align-items: center;justify-content: flex-end;margin-top: 20px;">
				<el-button @click="closedb" size="small">取消</el-button>
				<el-button type="success" @click="qrdb()" size="small">确定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				baseurl: '',
				showdetail: false,
				detail_id: '',
				searchinput: '',
				tableHeader: [{
						name: '标题',
						prop: 'title',
						width: ''
					},
					{
						name: '缩略图',
						prop: 'slt_url',
						width: '130',
						type:'img'
					},
					{
						name: '排序',
						prop: 'sort',
						width: '130'
					}
				],
				tableData: [],
				detail:'',
				isadd:true,
				ruleForm:{
					title:'',
					type:'',
					slt_url:'',
					fj_url:'',
					sort:'',
				},
				rules: {
					title: [{
						required: true,
						message: "标题不能为空",
						whitespace: true
					}],
					type: [{ required: true, message: '请选择所属书籍', trigger: 'change' }],
					slt_url: [{
						required: true,
						message: "缩略图不能为空",
						whitespace: true
					}],
					fj_url: [{
						required: true,
						message: "请上传附件",
						whitespace: true
					}],
				},
				
				imageUrl:'',
				imageUrl2:'',
				booklist:[],
				fileList:[]
			}
		},
		mounted() {			
			this.baseurl = this.$URL
			this.gettable()
			
			this.$post({
				url: '/api/book/book_list',
				params: {
					p:1,
					size:9999
				}
			}).then((res) => {
				this.booklist = res.list
			})
			
		},
		methods: {
			showpreview(file){
				window.open(file.url,'_blank')
			},
			qrdb(){
				this.$refs.ruleForm.validate((valid) => {
					if (valid) {
						this.$post({
							url: this.isadd?'/api/book/zj_add':'/api/book/zj_edit',
							params: this.ruleForm
						}).then((res) => {
							this.$message.success('操作成功')
							this.closedb()
							this.gettable()
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			closedb(){
				this.ruleForm = {
					title:'',
					type:'',
					slt_url:'',
					fj_url:'',
					sort:'',
				}
				this.fileList = []
				this.showdetail = false
			},
			look(item) {
				this.rowdata = item
				this.detail_id = item.id
				this.showdetail = true
			},
			addnew() {
				this.showdetail = true
				this.isadd=true
			},
			handleAvatarSuccessfile(res, file) {
				let obj ={
					name:res.file_name,
					url:res.fullurl,
					id:res.id
				}
				this.ruleForm.fj_url = res.fullurl
				this.fileList.push(obj)
			},
			handleChange(file, fileList) {
				this.fileList = fileList			    
			},
			handleAvatarSuccess(res, file) {
				this.ruleForm.slt_url = res.fullurl
				this.imageUrl = URL.createObjectURL(file.raw);
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg'||file.type === 'image/png';
				const isLt2M = file.size / 1024 / 1024 < 10;
			
				if (!isJPG) {
					this.$message.error('上传图片只能是 JPG或PNG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传图片大小不能超过 10MB!');
				}
				return isJPG && isLt2M;
			},
			reset() {
				this.searchinput = ''
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			search() {
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			gettable() {
				this.$post({
					url: '/api/book/zj_list',
					params: {		
						title:this.searchinput,
						p: this.$refs.zjlist.inputpage,
						size: this.$refs.zjlist.pagesize
					}
				}).then((res) => {					
					this.tableData = res.list
					this.$refs.zjlist.total = res.count
				})
			},
			deleterow(row) {
				this.$confirm('确认删除该条数据吗？')
					.then(_ => {
						this.$post({
							url: '/api/book/zj_del',
							params: {
								id: row.id
							}
						}).then((res) => {
							this.$message.success('删除成功')
							this.gettable()
						})
					})
					.catch(_ => {});

			},
			showsetrole(row) {
				console.log(row)
				this.$refs.mymx.showmx = true
			},
			changswitch(row) {
				console.log(row)
			},
			showedit(row) {
				this.$post({
					url: '/api/book/zj_detail',
					params: {
						id: row.id
					}
				}).then((res) => {
					console.log(res)
					
					this.ruleForm.id = res.id
					this.ruleForm.title = res.title
					this.ruleForm.slt_url = res.slt_url
					this.ruleForm.type = res.type
					this.fileList=[]
					let obj ={
						name:res.fj_url,
						url:res.fj_url
					}
					this.fileList.push(obj)
					this.ruleForm.fj_url = res.fj_url
					this.ruleForm.sort = res.sort		
					this.showdetail = true
					this.isadd=false
				})
				
				
			}
		}
	}
</script>

<style lang="scss" scoped>
	.box_main {
		background-color: #fff;
		padding: 15px 25px;
		width: 100%;
		box-sizing: border-box;
		min-height: 100%;
	}

	.search_box {
		display: flex;
		align-items: center;
		font-size: 0.16rem;

		.search_item {
			display: flex;
			align-items: center;
			margin-right: 0.8rem;

		}
	}

	.box_cont {
		margin-top: 20px;
	}

	.btn_list {
		display: flex;
		align-items: center;
	}

	.tc_title {
		font-size: 0.18rem;
		font-weight: bold;
		color: #333;
		position: relative;
		padding-left: 20px;
		margin-bottom: 20px;
	}

	.tc_title:after {
		content: '';
		position: absolute;
		height: 100%;
		width: 4px;
		left: 0px;
		background-color: rgb(26, 144, 253);
	}

	::v-deep td {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell>.cell {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell.is-leaf {
		background-color: rgb(242, 242, 242);
		color: #777;
	}

	::v-deep .el-table th.el-table__cell.is-leaf,
	.el-table td.el-table__cell {}

	::v-deep .el-table td.el-table__cell {}

	::v-deep .el-table {
		margin-top: 10px;

	}

	::v-deep .el-table__fixed {}

	::v-deep .el-table::before {
		background-color: #fff !important;
	}

	::v-deep .el-table__fixed::before {
		background-color: #fff !important;
	}

	::v-deep .el-dialog__header {
		display: flex !important;
	}

	::v-deep .el-dialog__footer span {
		display: flex !important;
		justify-content: space-around;
		width: 100%;
	}
	::v-deep .avatar-uploader .el-upload {
		border: 1px dashed #999;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}
	
	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}
	
	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 145px;
		height: 145px;
		line-height: 145px;
		text-align: center;
	}
	
	.avatar {
		width: 145px;
		height: 145px;
		display: block;
	}
	
	::v-deep .el-icon-circle-check:before {
		font-size: 0.2rem;
	}
	
	::v-deep .el-icon-circle-check {
		font-size: 0.2rem !important;
		line-height: 0.16rem;
		height: 0.16rem;
	}
	
	::v-deep .el-dialog__header {
		display: flex;
	}
</style>